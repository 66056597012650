import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import 'react-datetime/css/react-datetime.css';
import 'react-toastify/dist/ReactToastify.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  Observable,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";


import refreshToken from './helpers/refreshToken';
import { toast } from 'react-toastify';



const API_URL = process.env.REACT_APP_API_URL + "/query";
const API_WS = process.env.REACT_APP_API_WS + "/query";

const App = () => {

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      if (graphQLErrors.find(error => error.message.includes('error jwt'))) {
        return new Observable(observer => {
          refreshToken().then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            };
            forward(operation).subscribe(subscriber);
          })
            .catch(error => {
              observer.error(error);
            });
        });
      }
      graphQLErrors.forEach(({ message }) =>
        toast.error(`${message}`, { autoClose: false })
      );
    }
    if (networkError) {
      if (networkError.statusCode === 401) {
        alert("Veuillez utiliser une clé API valide X-API-Key");
      }
    };
  });

  const uploadLinkWithUpload = createUploadLink({ uri: API_URL })

  const wsLink = new GraphQLWsLink(createClient({
    url: API_WS,
    connectionParams: () => {
      return {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        "X-API-Key": process.env.REACT_APP_API_KEY
      }
    }
  }));

  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('accessToken');
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
        "X-API-Key": process.env.REACT_APP_API_KEY
      }
    }
  });

  const baseOptions = {
    errorPolicy: "all"
  }

  const client = new ApolloClient({
    defaultOptions: {
      watchQuery: baseOptions,
      query: baseOptions,
      mutate: baseOptions,
    },
    link: ApolloLink.from([errorLink, authLink, uploadLinkWithUpload, wsLink]),
    cache: new InMemoryCache(),
    name: `${process.env.REACT_APP_NAME}`,
    version: `${process.env.REACT_APP_API_VERSION}`
  });

  return (
    <ApolloProvider client={client}>
      <Router basename={process.env.PUBLIC_URL}>
        <Layout />
      </Router>
    </ApolloProvider>
  );
};

export default App;
