import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ProvideAuth, useAuth } from '../hooks/useAuth';
import { ToastContainer } from 'react-toastify';
// import { CloseButton, Fade } from '../components/common/Toast';
import Loader from '../components/common/Loader';
import 'react-toastify/dist/ReactToastify.css';

const DashboardLayout = lazy(() => import('./DashboardLayout'));
const AuthBasicLayout = lazy(() => import('./AuthBasicLayout'));
const ErrorLayout = lazy(() => import('./ErrorLayout'));


const Layout = () => {
  return (
    <Suspense fallback={<span />}>
      <ProvideAuth>

          <Routes>
            <Route path="/auth/*" element={<AuthBasicLayout/>} />
            <Route path="/errors/*" element={<ErrorLayout/>} />

            <Route path="/*" element={<PrivateRoute element={<DashboardLayout />} />} />
          </Routes>
          {/* <ToastContainer closeButton={<CloseButton />} transition={Fade} closeOnClick={true} pauseOnFocusLoss={false} draggable={false} position={toast.POSITION.BOTTOM_CENTER} autoClose={100} /> */}
          <ToastContainer position={'bottom-center'} bodyStyle={{color:"white"}} />

      </ProvideAuth>
    </Suspense>
  );
};

export default Layout;

const PrivateRoute = ({ element }) => {
  const { loggedIn, user } = useAuth()

  if (loggedIn() && !user) {
    return <Loader type="border" />
  }

  return loggedIn() ? (
    element
  ) : (
    <Navigate to="/auth/login" replace />
  );
};